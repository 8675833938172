import { Link, withIntl } from '../../i18n'

import Banner from '../../components/reusables/banner'
import Layout from '../../components/layout'
import PrismicTiles from '../../components/reusables/prismicTiles'
import React from 'react'
import { graphql } from 'gatsby'

const Meetups = ({ data }) => (
  <Layout>
    <Banner data={data} shade={'light'} />
    <PrismicTiles data={data.allPrismicEvents} shade={'light'} />
  </Layout>
)

export default withIntl(Meetups)

export const blogQuery = graphql`
  query videos($locale: String!) {
    allPrismicEvents(
      filter: { lang: { eq: $locale } }
      sort: { fields: [data___published_date], order: DESC }
    ) {
      edges {
        node {
          lang
          uid
          data {
            title {
              text
            }
            link {
              url
            }
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 200) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
    bannerGlobe: file(relativePath: { eq: "hero/bannerGlobe@3x.png" }) {
      ...bannerIcon
    }
  }
`
