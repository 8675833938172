import {
  StyledColumnContainer,
  StyledColumnsContainer,
  StyledContainer,
  StyledH2,
  StyledH3,
  StyledP,
  StyledUnderlinedH2,
} from './styles'
import { injectIntl, intlShape } from 'react-intl'

import { Box } from 'grommet'
import Img from 'gatsby-image'
import Link from './link'
import PropTypes from 'prop-types'
import React from 'react'
import media from 'styled-media-query'
import shortid from 'shortid'
import styled from 'styled-components'

const StyledRowContainer = styled.div`
  width: 100%;
  text-align: center;
`

const StyledTileContainer = styled(StyledColumnContainer)`
  width: auto;
  display: grid;
  margin: 5px;
  justify-items: start;
  align-items: start;
  ${media.greaterThan('small')`
    width: 400px;
  `}

  p {
    text-align: left;
  }
`

const StyledTilesContainer = styled(StyledColumnsContainer)`
  justify-content: flex-start;
  margin: 0 -20px;
  padding: 0;
`

const StyledImageContainer = styled(Box)`
  width: 240px;
  height: auto;
  ${media.greaterThan('small')`
    height: 200px;
    width: 400px;
  `}
`

const StyledDate = styled(StyledP)`
  margin-top: 15px;
  font-size: 16px;
  opacity: 0.7;
`
const PrismicTiles = ({ data, shade }) => {
  return (
    <StyledContainer componentName="PrismicTiles" shade={shade}>
      <StyledTilesContainer columns={3}>
      {data && (
        <StyledRowContainer>
          <StyledTilesContainer columns={3}>
            {data.edges.map(({ node }, index) => {
              return (
                node.data.image.localFile && (
                  <StyledTileContainer
                    align="center"
                    key={shortid.generate()}
                    width={'100%'}
                  >
                    <a href={node.data.link.url} target="_blank">
                      <StyledImageContainer
                        justify="center"
                        round="1px"
                        background={
                          shade === 'light'
                            ? 'primaryGrey'
                            : shade === 'grey'
                            ? 'primaryWhite'
                            : 'primaryDark'
                        }
                      >
                        <Img
                          alt="hero image for post"
                          imgStyle={{ objectFit: 'contain' }}
                          fluid={
                            node.data.image.localFile.childImageSharp.fluid
                          }
                        />
                      </StyledImageContainer>
                    </a>
                    <StyledP>{node.data.title.text}</StyledP>
                  </StyledTileContainer>
                )
              )
            })}
          </StyledTilesContainer>
        </StyledRowContainer>
      )}
      </StyledTilesContainer>
    </StyledContainer>
  )
}

PrismicTiles.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
  shade: PropTypes.string,
  index: PropTypes.number,
  intl: intlShape.isRequired,
}

export default injectIntl(PrismicTiles)
